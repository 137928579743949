<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <UserToolbar
      v-if="account && Object.keys(account).length !== 0"
      :account="account"
    />
    <b-card>
      <CampaignToolbar
        v-if="Object.keys(campaign).length !== 0"
        :campaign="campaign"
        closing
      />
      <hr>
      <div class="head-card">
        <div class="head-card-top">
          <!-- datepicker -->
          <div class="d-flex align-items-center">
            <feather-icon
              icon="CalendarIcon"
              size="16"
            />
            <flat-pickr
              v-model="rangePicker"
              :config="{ mode: 'range'}"
              class="form-control flat-picker bg-transparent border-0 shadow-none"
              placeholder="YYYY-MM-DD"
              @on-change="fetchCampaignStatistics"
            />
          </div>
          <!-- datepicker -->
        </div>
      </div>
      <app-echart-bar
        id="echart"
        :option-data="option"
      />
    </b-card>
    <b-card v-if="campaignimpressionstats.length">
      <app-collapse
        accordion
        type="border"
        class="mt-2 collapse-sites"
      >
        <app-collapse-item
          v-for="(item, index) in rowsReverse"
          :key="item._id"
          :title="getFormattedDate(new Date(item.datetime))"
          :is-visible="!index"
        >
          <b-table-lite
            hover
            striped
            :items="[...item.stats]"
          />
        </app-collapse-item>
        <app-collapse-item
          :title="$t('Total')"
          :is-visible="false"
        >
          <b-table-lite
            hover
            striped
            :items="[...totalsPeriodStats]"
          />
        </app-collapse-item>
      </app-collapse>
    </b-card>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import echarts from 'echarts/lib/echarts'
import getFormattedDate from '@/utils/date-formatter'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BCard, BTableLite, VBTooltip,
} from 'bootstrap-vue'
import AppEchartBar from '@core/components/charts/echart/AppEchartBar.vue'
import flatPickr from 'vue-flatpickr-component'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import UserToolbar from '@/views/dashboard/users/UserToolbar.vue'
import CampaignToolbar from '@/views/dashboard/campaigns/CampaignToolbar.vue'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BCard,
    BTableLite,
    AppEchartBar,
    flatPickr,
    AppCollapse,
    AppCollapseItem,
    Breadcrumbs,
    UserToolbar,
    CampaignToolbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      authUserData: getUserData(),
      account: {},
      campaign: [],
      breadcrumbItems: [],
      getFormattedDate,
      campaignimpressionstats: [],
      option: {
        xAxis: [
          {
            type: 'category',
            data: [],
          },
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: { show: false },
          },
        ],
        calculable: true,
        grid: {
          left: '70px',
          right: '30px',
          top: '20%',
          bottom: '30px',
        },
        series: [],
      },
      rangePicker: [getFormattedDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)), getFormattedDate(new Date())],
    }
  },
  computed: {
    rowsReverse() {
      const rowsitems = this.campaignimpressionstats
      return rowsitems.reverse()
    },
    totalsPeriodStats() {
      const impressionsBySite = this.rowsReverse.reduce((accumulator, entry) => {
        const { stats } = entry
        stats.forEach(stat => {
          const { site, impressions } = stat
          if (accumulator[site]) {
            accumulator[site] += impressions
          } else {
            accumulator[site] = impressions
          }
        })
        return accumulator
      }, {})

      return Object.keys(impressionsBySite).map(site => ({
        site,
        impressions: impressionsBySite[site],
      }))
    },
  },
  created() {
    this.getUserData()
  },
  mounted() {
    this.fetchCampaignStatistics()
  },
  methods: {
    async getUserData() {
      const responseData = await useJwt.getUser(this.$route.params.userId)
      this.account = responseData.data.account || {}
      this.pageTitle = this.account.name
      this.pageSubtitle = this.account.email
      this.getCampaignData()
    },
    async getCampaignData() {
      const responseData = await useJwt.getCampaign(this.$route.params.id)
      this.campaign = responseData.data.campaign || []
      this.setBreabcrumbs()
    },
    async fetchCampaignStatistics() {
      let dateFrom
      let dateTo
      const selectedRange = this.rangePicker
      const series = []
      const groupSeries = []
      if (Array.isArray(selectedRange)) {
        [dateFrom, dateTo] = selectedRange
      } else {
        [dateFrom, , dateTo] = selectedRange.split(' ')
      }
      if (dateTo === undefined) {
        dateTo = dateFrom
      }
      await fetch(`https://api.dashboard.phoenix-widget.com/campaignimpressionstats?from=${dateFrom}&to=${dateTo}&campaign_id=${this.$route.params.id}`, {
        headers: {
          Accept: 'application/json, text/javascript, */*; q=0.01',
          'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
          Authorization: `Bearer ${useJwt.getToken()}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          const { campaignimpressionstats } = data
          const categories = []
          if (campaignimpressionstats.length) {
            campaignimpressionstats.forEach(element => {
              const date = getFormattedDate(new Date(element.datetime))
              categories.push(date)
              if (dateFrom === dateTo) {
                element.stats.forEach(stat => {
                  series.push({ name: stat.site, data: [stat.impressions] })
                })
              } else {
                element.stats.forEach(stat => {
                  groupSeries.push({ date, name: stat.site, data: stat.impressions })
                })
              }
            })
            const grouped = this.groupByKey(groupSeries)
            if (grouped) {
              Object.keys(grouped).forEach(key => {
                series.push({
                  name: key,
                  type: 'bar',
                  stack: 'advertising',
                  data: grouped[key],
                  barMaxWidth: '20%',
                  barMinWidth: '10px',
                })
              })
            }
            this.updateCharOptions({
              xAxis: [
                {
                  type: 'category',
                  data: categories,
                },
              ],
              series,
            })
            this.campaignimpressionstats = campaignimpressionstats
          }
        })
        .catch(() => {})
    },
    updateCharOptions(newOption) {
      const chart = echarts.init(document.querySelector('#echart'))
      setTimeout(() => {
        chart.setOption(newOption)
        chart.resize()
      }, 500)
    },
    groupByKey(myArray) {
      const groups = {}
      for (let i = 0; i < myArray.length; i += 1) {
        const groupName = myArray[i].name
        if (!groups[groupName]) {
          groups[groupName] = []
        }
        groups[groupName].push(myArray[i].data)
      }
      const newArray = []
      Object.keys(groups).forEach(key => {
        newArray.push({
          name: key,
          type: 'bar',
          stack: 'advertising',
          data: groups[key],
          barMaxWidth: '20%',
          barMinWidth: '10px',
        })
      })
      return groups
    },
    reGroup(data, property) {
      return data.reduce((acc, obj) => {
        const key = obj[property]
        if (!acc[key]) {
          acc[key] = []
        }
        acc[key].push(obj)
        return acc
      }, {})
    },
    setBreabcrumbs() {
      this.breadcrumbItems = [
        {
          text: 'users',
          to: '/users',
        },
        {
          text: this.pageTitle,
          to: `/users/${this.$route.params.userId}`,
        },
        {
          text: 'campaigns',
          to: `/users/${this.$route.params.userId}/campaigns`,
        },
        {
          text: this.campaign.title,
          to: `/users/${this.$route.params.userId}/campaigns/${this.$route.params.id}`,
        },
        {
          text: 'stats_per_sites',
          active: true,
        },
      ]
    },
  },
}
</script>
<style scoped>
.echarts {
  width: 100% !important
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
